<template>
<AuthLayout
  :title="`Requests`"
>
<template #content>
  <div class="RequestsPage">
    
    <RequestsFilter />
    
    <RequestsTable />
    
    <RequestsPagination />
    
  </div>
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/cms/auth.layout/AuthLayout";
import RequestsFilter from "@/components/cms/requests/RequestsFilter";
import RequestsTable from "@/components/cms/requests/RequestsTable";
import RequestsPagination from "@/components/cms/requests/RequestsPagination";

export default {
  name: "RequestsPage",
  components: {
    RequestsPagination,
    AuthLayout,
    RequestsFilter,
    RequestsTable,
  },
}
</script>

<style lang="scss" scoped>
.RequestsPage {
  width: 100%; // scrollbar-width: thin fix
}
</style>