<template>
<div class="RequestsPagination">
  
  <template v-if="requestsPagination && requestsPagination.total > 0">
    <div class="RequestsPagination__total">
      {{ `${requestsPagination.from}-${requestsPagination.to} of ${requestsPagination.total} items` }}
    </div>
    <a-pagination
      @change="pageChange"
      :current="requestsPagination.current_page"
      :pageSize="requestsPagination.per_page"
      :total="requestsPagination.total"
      :show-size-changer="false"
    />
  </template>
  
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "RequestsPagination",
  computed: {
    ...mapGetters({
      requestsPagination: 'CmsRequestStore/getPagination',
      getPayload: 'CmsRequestStore/getPayload',
    }),
  },
  methods: {
    ...mapActions({
      fetchRequests: 'CmsRequestStore/fetch',
    }),
    ...mapMutations({
      setPayloadField: 'CmsRequestStore/setPayloadField',
    }),
    async pageChange(page) {
      this.setPayloadField({ name: 'RequestsFilter', field: 'page', value: page})
      await this.fetchRequests(this.getPayload('RequestsFilter'))
    },
  },
}
</script>

<style lang="scss" scoped>
.RequestsPagination {
  background-color: white;
  text-align: center;
  position: relative;
  padding: 24px 0 15px 0;
  
  &__total {
    color: $secondary45;
    position: absolute;
    top: 0;
    left: 24px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>