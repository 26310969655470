<template>
<div class="RequestsFilter">
  
  <div class="RequestsFilter__tabs">
    <div
      v-for="(tab, i) in tabs" :key="tab.label"
      @click="tabChange(tab)"
      :class="{
        'RequestsFilter__tab': true,
        'RequestsFilter__tab-active': xor(tab.statuses, form_statuses).length === 0 ||
                                      (tab.statuses.length === requestStatuses.length && form_statuses.length === 0)
      }"
    >
      {{ tab.label }}
    </div>
  </div>
  
  <div class="RequestsFilter__form-wrap">
    <a-form
      ref="form"
      class="RequestsFilter__form"
      :model="formState"
      layout="inline"
      :colon="false"
    >
      <a-form-item
        class="RequestsFilter__form-item"
        :style="`--width: 35%`"
        :label="formSetup.search.label"
        name="form_search"
      >
        <a-input
          v-model:value="form_search"
          :placeholder="formSetup.search.placeholder"
          :allowClear="true"
          :disabled="loading"
        />
      </a-form-item>
  
      <a-form-item
        class="RequestsFilter__form-item"
        :style="`--width: 25%`"
        :label="formSetup.test_item_ids.label"
        name="form_test_item_ids"
      >
        <a-select
          v-model:value="form_test_item_ids"
          mode="multiple"
          max-tag-count="responsive"
          :allowClear="true"
          :placeholder="formSetup.test_item_ids.placeholder"
          :options="testItems"
          :fieldNames="{ label: 'title', value: 'id' }"
          :showArrow="true"
        >
          <template #suffixIcon>
            <CaretDownOutlined
              class="RequestsFilter__caret ant-select-suffix ant-select-caret"
            />
          </template>
        </a-select>
      </a-form-item>
  
      <a-form-item
        class="RequestsFilter__form-item"
        :style="`--width: 25%`"
        :label="formSetup.statuses.label"
        name="form_statuses"
      >
        <a-select
          v-model:value="form_statuses"
          mode="multiple"
          max-tag-count="responsive"
          :allowClear="true"
          :placeholder="formSetup.statuses.placeholder"
          :options="requestStatuses"
          :showArrow="true"
        >
          <template #suffixIcon>
            <CaretDownOutlined
              class="RequestsFilter__caret ant-select-suffix ant-select-caret"
            />
          </template>
        </a-select>
      </a-form-item>
  
      <a-form-item
        class="RequestsFilter__form-item RequestsFilter__form-buttons"
        :style="`--width: 15%`"
      >
        <a-button
          html-type="reset"
          class="RequestsFilter__button-reset"
          @click="formReset"
        >
          Reset
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          class="RequestsFilter__button-submit"
          @click="formSubmit"
        >
          Show
        </a-button>
      </a-form-item>
    </a-form>
  </div>
  
</div>
</template>

<script>
import { CaretDownOutlined } from '@ant-design/icons-vue'
import { mapFields } from '@/helpers/form.helper'
import { mapActions, mapGetters, mapMutations } from "vuex"
import requestStatuses from '@/vocabulary/requestStatuses.options.json'
import _ from "lodash"

let serverErr, formSetup = {
  search: {
    label: 'Search',
    placeholder: 'Barcode or patient name',
    // rules: [],
  },
  test_item_ids: {
    label: 'Test',
    placeholder: 'Select',
    // rules: [],
  },
  statuses: {
    label: 'Status',
    placeholder: 'Select',
    // rules: [],
  },
}

export default {
  name: "RequestsFilter",
  components: {
    CaretDownOutlined,
  },
  data() {
    return {
      loading: false,
      formSetup,
      requestStatuses,
      tabs: [
        {
          label: 'In Progress',
          statuses: ["new_request", "symptoms_added", "conclusion_added", "test_completed"],
        },
        {
          label: 'Completed',
          statuses: ["report_completed"],
        },
        {
          label: 'All Requests',
          statuses: ["new_request", "symptoms_added", "conclusion_added", "report_completed", "test_completed"],
        },
      ],
    }
  },
  computed: {
    ...mapFields({
      formSetup,
      props: ['fields', 'formState'], // 'formRules'
      getter: (vm, field) => vm.getPayload('RequestsFilter')[field],
      setter: (vm, field, value) => vm.setPayloadField({ name: 'RequestsFilter', field, value}),
    }),
    ...mapGetters({
      getPayload: 'CmsRequestStore/getPayload',
      init: 'CmsInitialStore/getInit',
    }),
    testItems() {
      return this.init.test_items
    },
  },
  methods: {
    xor(v1, v2) { return _.xor(v1, v2) }, // lodash xor
    ...mapActions({
      fetchRequests: 'CmsRequestStore/fetch',
    }),
    ...mapMutations({
      setPayloadField: 'CmsRequestStore/setPayloadField',
      setPayloadInitial: 'CmsRequestStore/setPayloadInitial',
    }),
    tabChange(tab) {
      this.form_statuses = tab.statuses.length === this.requestStatuses.length ? [] : tab.statuses
      this.formSubmit()
    },
    formReset() {
      this.setPayloadInitial('RequestsFilter')
      this.formSubmit()
    },
    async formSubmit() {
      this.setPayloadField({ name: 'RequestsFilter', field: 'page', value: 1 })
      await this.fetchRequests(this.getPayload('RequestsFilter'))
    },
  },
}
</script>

<style lang="scss" scoped>
.RequestsFilter {
  
  &__tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  
  &__caret {
    font-size: 11px;
    margin-top: 1px;
  }
  
  &__tab {
    padding: 9px 16px;
  }
  
  &__tab:not(&__tab-active) {
    background-color: $neutral4;
    cursor: pointer;
  }
  
  &__tab-active {
    background-color: white;
  }
  
  &__form-wrap {
    background-color: white;
    padding: 24px;
  }
  
  &__form {
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
  }
  
  &__form-item {
    flex: 1 1 var(--width) !important;
  }
  
  &__form-buttons:deep(.ant-form-item-control-input-content) {
    display: flex;
  }
  
  &__button-reset {
    margin-right: 10px;
    flex: 1 1 0;
  }
  
  &__button-submit {
    flex: 1 1 0;
  }
  
}
</style>