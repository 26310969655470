<template>
<div class="RequestsTable">
  
  <a-table
    :dataSource="allRequests"
    :columns="columns"
    :pagination="false"
    :customRow="customRow"
    :scroll="{ y: `calc(100vh - ${sum([
      48,     // HeaderLayout
      72,     // SubHeaderLayout
      120,    // RequestsFilter
      55,     // ant-table-header
      71,     // RequestsPagination
      24,     // AuthLayout__wrap
    ])}px)` }"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'action'">
        
        <template v-if="record.status === 'report_completed'">
          <a-dropdown
              :disabled="!record.report_completed_at"
              class="RequestsTable__button RequestsTable__button-download"
              type="primary"
          >
            <a-button
                class="RequestsTable__button RequestsTable__button-download"
                type="primary"
            >
              <template #icon>
                <CaretDownOutlined />
              </template>
            </a-button>
            <template #overlay>
              <a-menu
                  @click="(event) => handleDownloadMenuClick(event, record)"
              >
                <a-menu-item
                    v-if="record.report_completed_at"
                    key="report_pdf"
                >
                  Download Report (PDF)
                </a-menu-item>
                <a-menu-item
                    v-if="record.report_completed_at"
                    key="report_word"
                >
                  Download Report (Word)
                </a-menu-item>
                <a-menu-item
                    v-if="record.agreement?.consent_form_read_at"
                    key="consent"
                >
                  Download Consent Form
                </a-menu-item>
                <a-menu-item
                    key="edit"
                >
                  Edit report
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

        </template>
        <template v-else-if="record.status === 'test_completed'">
          <a-button
            class="RequestsTable__button RequestsTable__button-arrow"
            :disabled="true"
          >
            <template #icon>
              <ArrowRightOutlined />
            </template>
          </a-button>
        </template>
        <template v-else>
          <a-button
            @click="goNext(record)"
            class="RequestsTable__button RequestsTable__button-arrow"
            type="green"
          >
            <template #icon>
              <ArrowRightOutlined />
            </template>
          </a-button>
        </template>
        
      </template>
    </template>
  </a-table>
  
</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import { ArrowRightOutlined, DownloadOutlined, CaretDownOutlined } from '@ant-design/icons-vue'
import { CmsReportService } from "@/services/api_v1/cms"
import _ from "lodash"

export default {
  name: "RequestsTable",
  components: {
    ArrowRightOutlined,
    DownloadOutlined,
    CaretDownOutlined,
  },
  data() {
    return {
      // current: 1,
      columns: [
        {
          key: 'barcode',
          dataIndex: 'barcode',
          title: 'Barcode',
          width: 130,
        },
        {
          key: 'patient.dgl',
          dataIndex: ['patient', 'dgl'],
          title: 'DGL',
          width: 80,
        },
        {
          key: 'patient.dob',
          dataIndex: ['patient', 'dob_formatted'],
          title: 'Date of birth',
          width: 130,
        },
        {
          key: 'patient.name',
          dataIndex: ['patient', 'name'],
          title: 'Patient Name',
        },
        {
          key: 'test_item.title',
          dataIndex: ['test_item', 'title'],
          title: 'Type of test',
          width: 130,
        },
        {
          key: 'test_taken_at',
          dataIndex: 'test_taken_formatted',
          title: 'Test Taken',
          width: 130,
        },
        {
          key: 'gc_analysis_at',
          dataIndex: 'gc_analysis_formatted',
          title: 'GC Analysis',
          width: 130,
        },
        {
          key: 'report_completed_at',
          dataIndex: 'report_completed_formatted',
          title: 'Report Sent',
          width: 130,
        },
        {
          key: 'status',
          dataIndex: 'status_formatted',
          title: 'Status',
        },
        {
          key: 'action',
          title: 'Action',
          width: 80,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      requestsIsInit: 'CmsRequestStore/getIsInit',
      allRequests: 'CmsRequestStore/getAll',
      getPdf: 'CmsRequestStore/getPdf',
      getPayload: 'CmsRequestStore/getPayload',
    }),
  },
  methods: {
    sum(v) { return _.sum(v) }, // lodash sum
    ...mapMutations({
      setPdf: 'CmsRequestStore/setPdf',
    }),
    ...mapActions({
      fetchRequests: 'CmsRequestStore/fetch',
    }),
    customRow(record) {
      return {
        onClick: (event) => {
          let targetClass = event.target.className
          if (_.isString(targetClass) && targetClass.includes("ant-table-cell")) {
            this.$router.push({ name: 'cms.request', params: { barcode: record.barcode } })
          }
        }
      }
    },
    goNext(record) {
      const match = {
        new_request: 'cms.symptoms',
        symptoms_added: 'cms.conclusion',
        conclusion_added: 'cms.report',
      }
      this.$router.push({ name: match[record.status], params: { barcode: record.barcode } })
    },
    async download(record) {
      const link = document.createElement('a'),
            pdf = this.getPdf(record.barcode) || null
      link.download = `${record.barcode}.pdf`
      
      if (!pdf) {
        await CmsReportService.download(record.barcode)
          .then((response) => {
            if (response.status === 200) {
              this.setPdf({ barcode: record.barcode, value: response.data })
              link.href = response.data
              link.click()
              link.remove()
            }
          })
      } else {
        link.href = pdf
        link.click()
        link.remove()
      }
    },
    async handleDownloadMenuClick(event, record) {
      if (event.key === "report_pdf") {
        await this.download(record)
      }
      if (event.key === "report_word") {
        const link = document.createElement('a')
        link.download = `${record.barcode}.docx`
        await CmsReportService.downloadWord(record.barcode)
            .then((response) => {
              if (response.status === 200) {
                link.href = response.data
                link.click()
                link.remove()
              }
            })
      }
      if (event.key === "consent") {
        const link = document.createElement('a')
        link.download = `${record.patient.name_underscore}-${record.barcode}_Consent.pdf`
        await CmsReportService.downloadConsentForm(record.barcode)
          .then((response) => {
            if (response.status === 200) {
              link.href = response.data
              link.click()
              link.remove()
            }
          })
      }
      if (event.key === "edit") {
        this.$router.push({ name: 'cms.symptoms', params: { barcode: record.barcode } })
      }
    },
  },
  async mounted() {
    if (!this.requestsIsInit) {
      await this.fetchRequests(this.getPayload('RequestsFilter'))
    }
  },
}
</script>

<style lang="scss" scoped>
.RequestsTable {
  background-color: white;
  padding: 0 24px 0 24px;
  box-sizing: border-box;
  
  &:deep(.ant-table-cell) {
    cursor: pointer;
  }
  
  &:deep(.ant-table-body) {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
    
    &::-webkit-scrollbar {
        width: 13px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px 15px 15px 5px;
        background-color: rgba(0, 0, 0, 0.15);
        border-right: 8px transparent solid;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        margin: 10px;
    }
  }
  
  &__barcode {
    color: $primary8;
  }
}
</style>